import styled from '@emotion/styled'

import { sectionTitle, mediaMax } from '@/styles/mixins'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-bottom: 8.4rem;
`

export const GridContainer = styled(GridContainerBase)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  ${mediaMax.xs}{
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  ${mediaMax.xs}{
    width: 100%;
    margin-top: 3rem;
  }
`

export const Title = styled.h3`
  ${sectionTitle};
  font-size: 10rem;
  text-transform: uppercase;
  margin-bottom: 4.1rem;
`

export const Text = styled(WysiwygBase)`
  width: 70%;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grey};
  ${mediaMax.xs}{
    width: 100%;
  }
`

export const ListWrapper = styled(Wrapper)`
`
