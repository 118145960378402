import styled from '@emotion/styled'

import { sectionTitle, sectionSubtitle, mediaMax } from '@/styles/mixins'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import { LinkWrapper as LinkWrapperBase } from '@/components/Link/style'
import { ButtonWrapper as Button } from '@/components/Button/style'
import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const GridContainer = styled(GridContainerBase)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${mediaMax.xs}{
    padding: ${p => p.theme.paddingX.m};
    box-sizing: border-box;
    width: 100VW;
  }
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TopWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Subtitle = styled.span`
  ${sectionSubtitle()};
`

export const Title = styled.h3`
  ${sectionTitle()};
  margin: 3.1rem 0;
  ${mediaMax.xs}{
    margin-top: 2.5rem;
  }
`

export const Text = styled(WysiwygBase)`
  font-size: 1.4rem;
  line-height: 2.6rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.greyText};
  text-align: center;
  ${mediaMax.xs}{
    text-align: left;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3.2rem;
  ${mediaMax.xs}{
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
  }
`

export const Item = styled.div`
  margin-bottom: 1.8rem;
  width: calc((100% - 3.6rem) / 3);
  padding: 4rem 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightGrey4};
  ${mediaMax.xs}{
    width: 100%;
    padding: 2.4rem 5rem;
    margin-bottom: 3rem;
  }
`

export const IconWrapper = styled.div`
  margin-bottom: 1rem;
  // ${mediaMax.xs}{
  //   margin-bottom: 3.6rem;
  // }
`

export const ItemText = styled(WysiwygBase)`
  font-size: 1.2rem;
  line-height: 2.2rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.greyText};
  text-align: center;
`

export const BottomContainer = styled.div`
  margin-top: 2.2rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${mediaMax.xs} {
    margin-top: 0;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    gap: 2rem;
    &.many {
      ${Button}{
        padding: 2rem 2.4rem;
      }
    }
  }

  ${LinkWrapperBase} {
    margin-left: 5rem;
    ${mediaMax.xs}{
      margin-left: 0;
    }
  }
`

export const Image = styled.img`
  max-width: 80px;
  height: auto;
`